@import "../variables";

h1 {
  font-size: 60px;
  line-height: 75px;
}

h2 {
  font-size: 55px;
  line-height: 75px;
}

h3 {
  font-size: 34.62px;
  line-height: 40px;
}

h4 {
  font-size: 28.98px;
  line-height: 40.41px;
}

h5 {
  font-size: 25px;
  line-height: 35.41px;
}

h6 {
  font-size: 21.15px;
  line-height: 31.73px;
}

small {
  font-size: 14px;
  font-weight: normal;
}

.smaller{
  font-size: 12.34px;
  font-weight: 500;
  line-height: 18.51px;
  margin-bottom: 0;
}

.text-color{
  color: $text-color !important;
}

.text-20{
  font-size: 20px;
}

@media (max-width: 991px) {
  h1 {
    font-size: 45px;
    line-height: 55px;
  }

  h2 {
    font-size: 40px;
    line-height: 48px;
  }

  h3 {
    font-size: 30px;
    line-height: 35px;
  }

  h4 {
    font-size: 25px;
    line-height: 35.41px;
  }

  h5 {
    font-size: 21px;
    line-height: 25px;
  }

  h6 {
    font-size: 18px;
    line-height: 25px;
  }

  .text-20{
    font-size: 16px;
  }
}
