$header-height: 90px;
$text-color: #333333;

/*BRAND COLOR VARIABLE*/
$primary: #00628E;
$secondary: #b5e0ff;
$info: #FFAB00;
$success: #3fe877;
$warning: #d48e00;
$danger: #FF4C4C;
$light: #F2F2F2;
$black: #000000;
$white: #ffffff;
$dark: #333333;
$medium: #808080;

/*THEME VARIABLE*/
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "info": $info,
  "success": $success,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "black": $black,
  "white": $white,
  "dark": $dark,
  "medium": $medium,
);
