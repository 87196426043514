@import "../variables";

.card {
  border-radius: 10px;
  padding: 10px;
  background-color: $white;
  background-clip: border-box;
  border: 1px solid #F0F0F0;
  box-shadow: 0 1.01px 40.41px -9.09px #00000012;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;
  word-wrap: break-word;
  align-items: flex-start;
  gap: 1.25rem;
}

.card-body {
  flex: 1 1 auto;
  width: 100%;
  padding: 20px 25px;
}
