@import "../variables";


:root {
  --primary-bg-color: $primary;
  --primary-bg-hover: #2b3691;
  --primary-transparentcolor: rgba(45, 56, 146, 0.16);
  --primary-bg-border: #2D3892;
  --dark-theme: #0e0e23;
  --dark-body: #24243e;
  --dark-border: rgba(255, 255, 255, 0.15);
  --dark-color: #d0d0e2;
  --dark-primary: #6259ca;
  --indigo: #4b0082;
  --purple: #6f42c1;
  --pink: #f1388b;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: $white;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2D3892;
  --secondary: #3C91E6;
  --success: #0F973D;
  --info: #FFAB00;
  --warning: #ffc107;
  --danger: #CB1A14;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: "Inter", Roboto, sans-serif, "Helvetica Neue", Arial;
  --font-family-monospace: "Inter", Roboto, sans-serif, "Helvetica Neue", Arial;
}

* {
  box-sizing: border-box;
  font-family: "Inter", Roboto, sans-serif, "Helvetica Neue", Arial !important;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  font-family: "Inter", Roboto, sans-serif, "Helvetica Neue", Arial !important;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


body {
  margin: 0;
  padding: 0;
  font-family: "Inter", Roboto, sans-serif, "Helvetica Neue", Arial !important;
  letter-spacing: 0.16px;
  font-weight: 400;
  text-align: left;
  direction: ltr;
  font-size: 20px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-font-feature-settings: "liga" 0;
  font-feature-settings: "liga" 0;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-x: hidden;
  // -ms-overflow-y: scroll;
  width: 100%;
  position: relative;
  // scrollbar-width: none;
  // -ms-overflow-style: none;
  color: $text-color !important;
  background: $white;

  ::-webkit-scrollbar {
    width: 3px !important;
    background-color: transparent;
  }

    ::-webkit-scrollbar-button {
      display: none;
    }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-button {
    border-radius: 3px;
    background: linear-gradient(to top right, $secondary, $dark);
    border: 1px solid $secondary;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-button:hover {
    width: 2px;
    background: $primary;
  }
}

html, body, #root {
  height: 100%;
}

a {
  font-style: normal;
  font-weight: 500;
  color: $primary;
  font-size: 15px;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.01em;
  margin: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    height: auto;
  }
}


img {
  vertical-align: middle;
  border-style: none;
  object-fit: cover;
}

@media (max-width: 991px) {
  body {
    font-size: 16px;
    line-height: 25px;
  }
}
