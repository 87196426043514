@import "../variables";

.form-floating {
  label {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: left;
    color: $white;
    text-transform: uppercase;
    padding: 1rem 0.5rem 0 0;
  }
}

.form-floating {
  > {
    .form-control, .form-control-plaintext {
      &:focus, &:not(:placeholder-shown) {
        padding-top: 2rem;
        padding-bottom: 0.625rem;

        & ~ label {
          color: $light;

          &::after {
            background-color: transparent;
          }
        }
      }
    }
  }
  &.is-invalid {
    input{
      background-image: none;
      border-bottom-color: $danger !important;

      &:focus {
        border-bottom-color: $danger !important;
        box-shadow: none;
      }
    }
    label{
      color: $danger !important;
    }
  }
}


.form-floating > .form-control {
  padding: 1.5rem 0.5rem 0 3px;
}

.form-control {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 550;
  font-style: normal;
  line-height: 5px;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid $white;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0;
  color: $white;
  height: 58px;
  padding: 20px 32px 0 0;
  align-items: center;
  align-self: stretch;

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:focus {
    outline: 0;
    border-color: $primary;
    border-bottom: 1px solid $white;
    background-color: transparent;
    box-shadow: none;
    color: $white;
  }

  &::placeholder {
    color: $white;
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    opacity: 1;
    background-color: $white;
    color: $white;
  }
}
