@import "../variables";

header {
  height: $header-height;
  background-color: $white;

  box-shadow: 0 0 10px rgba(46, 47, 50, 0.08);

  & > nav {
    justify-content: space-between;
    display: flex;
    width: 100%;
    gap: 30px;
    text-align: center;
    padding: 15px 25px;
    flex: 0 0 auto;

    .nav {
      display: flex;
      gap: 40px;
    }
  }
}

.nav-link a{
  color: $text-color;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
  line-height: 12.34px;
  text-align: left;

  &:hover, &.active{
    color: $primary;
  }
  &.active{
    font-weight: 700;
  }
}

.menu {
  @extend .my-auto;
  display: none;
}

.mobile-header {
  ul {
    @extend .d-flex;
    @extend .flex-column;
    @extend .row-gap-4;
    padding: 0 7px 10px;

    li {
      list-style-type: none;
      text-align: center;
      padding-bottom: 20px;
      border-bottom: 0.5px solid $medium;
    }
  }
}

.offcanvas {
  &-header {
    height: ($header-height + 10px) !important;
    margin-bottom: 30px;
  }
}

@media (max-width: 1050px) {
  header > nav {
    .nav {
      gap: 20px;
    }
  }
}

@media (max-width: 991px) {
  header > nav {
    .nav, .nav-action {
      display: none;
    }
  }
  .menu {
    margin: auto 5px;
    display: block;
  }
}
