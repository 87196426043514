@import "../variables";

.breadcrumb {
  &-item {
    text-align: center;
    color: $text-color;
    text-decoration: underline;
    a{
      font-size: 18px;
      color: $text-color;
    }
    &.active{
      font-weight: 700;
      color: $primary;
    }

    &+&::before {
      content: ">";
      font-weight: 400;
      color: $text-color;
      font-size: 16px;
      padding: 0 10px 0 5px;
    }
  }
}

.btn {
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  height: 45px;
  width: fit-content;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: 0;
  gap: 10px;
  vertical-align: middle;
  user-select: none;
  padding: 8px 16px;
  border: 1px solid transparent;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-sm,
.btn-group-sm>.btn {
  height: 32px;
  font-size: 12px;
}

.btn-link {
  @extend .text-decoration-none
}

.btn-dark {
  color: $white;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: #333;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.6);
    background-color: lighten(#333, 5px);
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
  }
}

.btn-white{
  color: $primary;
  border: 1px solid $white;
  background-color: $white;

  &:hover {
    color: darken($primary, 20px);
    border: 1px solid $primary;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
  };

  &:disabled{
    color: #99B9C9;
    opacity: 0.875;
  }
}

@media (max-width: 500px){
.breadcrumb {
  &-item {
    a {
      font-size: 16px;
    }
  }
}
}
