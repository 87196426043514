.section{
  margin-top: 70px;
  margin-bottom: 100px;
  overflow: hidden;
}

.image-fluid{
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {

  .section{
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
