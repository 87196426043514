@import "../variables";

.bg-primary-gradient{
  background: linear-gradient(180deg, $secondary 0%, rgba(213, 237, 255, 0.568481) 18.00%, rgba(255, 255, 255) 25%);
}

.brands{
  width: 100%;
  min-height: 400px;
  padding: 50px 20px;
  margin: 20px 0;
  background:
    linear-gradient(180deg, $secondary 0%, rgba(213, 237, 255, 0.568481) 30%, rgba(255, 255, 255, 0) 70%),
    url("../../images/svgs/bg-clip.svg") no-repeat center/cover;
  background-blend-mode: overlay;
}

.raise-transition:hover {
  transform: translateY(-5px);
  transition: 1s ease-in-out;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1),
  0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
}

.scroll-to-top {
  @extend .position-fixed;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .text-white;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  background-color: $primary;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  z-index: 100;
  padding: 20px 0 25px;
  box-shadow: 1px 3px 12px #dfe0e0;
  transition: opacity 2.5s ease-in-out;

  &:hover {
    background-color: lighten($primary, 7.5px);
  }
}
