@import "../variables";

footer {
  width: 100vw;
  margin: 50px 0 30px;

  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    column-gap: 70px;
    row-gap: 50px;
    margin: 70px 0 50px;

    &-links {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 20px;
      column-gap: 75px;

      a {
        color: $text-color;
        font-size: 15px;
        font-weight: 400;
        line-height: 18.15px;

        &:hover {
          color: $primary;
          font-weight: 550;
        }
      }
    }
  }

  p {
    font-size: 15px;
  }

  h6 {
    font-size: 15px;
    line-height: 18.15px;
  }
}

@media (max-width: 500px) {
  footer {
    margin: 30px 0 15px;

    .footer {
      column-gap: 30px;
      margin: 50px 0 20px;

      &-links {
        row-gap: 25px;
        column-gap: 25px;
      }
    }
  }
}
