@import "../variables";

.main-body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
}

.page{
    position: relative;
    margin-top: calc($header-height);
    @extend .bg-primary-gradient;
}
